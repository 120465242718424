import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { DetailsItem, Flag, TeamAvatar, UsersAvatars } from '@components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "detailsitem"
    }}>{`DetailsItem`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={DetailsItem} mdxType="Props" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={1} __code={'<DetailsItem title=\"Founded\" value=\"1990\" />\n<DetailsItem title=\"Capacity\" value=\"23.000\" />\n<DetailsItem\n  title=\"Location\"\n  value=\"Barcelona, Spain\"\n  rightElement={<Flag code=\"ESP\" />}\n/>\n<DetailsItem\n  title=\"Home Team\"\n  value=\"FC Barcelona\"\n  rightElement={\n    <TeamAvatar\n      team={{\n        logo:\n          \'https://cdn.thefansarena.com/images/team/fffd9aae-ac3e-42ee-a1f9-868c6438012a.png\',\n      }}\n      size=\"small\"\n    />\n  }\n/>\n<DetailsItem\n  title=\"Checked in\"\n  value=\"1.2K fans\"\n  rightElement={\n    <UsersAvatars\n      users={[\n        {\n          id: \'id1\',\n          profilePicture: \'https://picsum.photos/400\',\n        },\n        {\n          id: \'id2\',\n          profilePicture: \'https://picsum.photos/420\',\n        },\n        {\n          id: \'id3\',\n          profilePicture: \'https://picsum.photos/430\',\n        },\n        {\n          id: \'id4\',\n          profilePicture: \'https://picsum.photos/400\',\n        },\n      ]}\n      size=\"small\"\n      rounded\n    />\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      DetailsItem,
      Flag,
      TeamAvatar,
      UsersAvatars,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <DetailsItem title="Founded" value="1990" mdxType="DetailsItem" />
    <DetailsItem title="Capacity" value="23.000" mdxType="DetailsItem" />
    <DetailsItem title="Location" value="Barcelona, Spain" rightElement={<Flag code="ESP" mdxType="Flag" />} mdxType="DetailsItem" />
    <DetailsItem title="Home Team" value="FC Barcelona" rightElement={<TeamAvatar team={{
        logo: 'https://cdn.thefansarena.com/images/team/fffd9aae-ac3e-42ee-a1f9-868c6438012a.png'
      }} size="small" mdxType="TeamAvatar" />} mdxType="DetailsItem" />
    <DetailsItem title="Checked in" value="1.2K fans" rightElement={<UsersAvatars users={[{
        id: 'id1',
        profilePicture: 'https://picsum.photos/400'
      }, {
        id: 'id2',
        profilePicture: 'https://picsum.photos/420'
      }, {
        id: 'id3',
        profilePicture: 'https://picsum.photos/430'
      }, {
        id: 'id4',
        profilePicture: 'https://picsum.photos/400'
      }]} size="small" rounded mdxType="UsersAvatars" />} mdxType="DetailsItem" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      